import { r as registerInstance, h } from './index-ea84587e.js';

const openSvg = "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\"><path fill=\"none\" stroke=\"currentColor\" stroke-width=\"2rem\" d=\"M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>\n";

const fgProductImagesCss = "fg-product-images{display:block}fg-product-images section{display:flex;align-items:center}fg-product-images img{max-width:100%}fg-product-images aside{width:23%;margin-right:12%}fg-product-images aside ol{list-style:none;margin:0;padding:0}fg-product-images aside li{position:relative;width:100%;height:6.5rem;margin-bottom:0.75rem;cursor:pointer;background-color:white}fg-product-images aside li::after{content:\"\";position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(255, 255, 255, 0.7);transition:opacity 0.2s}fg-product-images aside li:hover::after{opacity:0.5}fg-product-images aside li.is-current::after{opacity:0}fg-product-images li,fg-product-images .current{background:center center no-repeat;background-size:cover}fg-product-images .current{position:relative;width:78%;height:34rem}fg-product-images .current .open{position:absolute;top:0;right:0;display:block;opacity:0.4;width:3rem;height:3rem;padding:0.5rem;background:white;color:black;transition:opacity 0.2s ease}fg-product-images .current .open:hover{opacity:1}";

const FgProductImages = class {
  constructor(hostRef) {
    registerInstance(this, hostRef);
  }
  imagesChanged() {
    if (!this.images || !this.images.length) {
      return;
    }
    this.currentImage = this.images[0];
  }
  componentWillLoad() {
    this.imagesChanged();
  }
  render() {
    return (h("section", null, h("aside", null, h("ol", null, this.images.map(src => (h("li", { class: { 'is-current': src === this.currentImage }, style: { backgroundImage: `url(${src})` }, onClick: () => this.currentImage = src }))))), h("div", { class: "current", style: { backgroundImage: `url(${this.currentImage})` } }, h("a", { class: "open", title: "Open image in new window", href: this.currentImage, target: "_blank", innerHTML: openSvg }))));
  }
  static get watchers() { return {
    "images": ["imagesChanged"]
  }; }
};
FgProductImages.style = fgProductImagesCss;

export { FgProductImages as fg_product_images };
